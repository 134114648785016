<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb_area" v-if="type === 'area' && !hideCondition">
      <div class="container">
        <ol class="arrows">
          <li v-if="landingQuery">
            <a :href="landingQuery">{{ $t("product-page.main") }}</a>
          </li>
          <li v-else>
            <router-link :to="homeLink">{{ $t("product-page.main") }}</router-link>
          </li>
          <li v-if="landingQuery">
            <a :href="landingQuery">{{ data.text }}</a>
          </li>
          <li v-else>
            <router-link :to="homeLink">{{ data.text }}</router-link>
          </li>
          <li>
            <router-link :to="data.searchQuery || searchQuery" onclick="return false;">{{ $t("product-page.packages-to") }}{{ data.dest }}</router-link>
          </li>
          <li>{{ data.stage }}</li>
        </ol>
      </div>
    </div>
    <div class="breadcrumb_area" v-if="type === 'search-area' && !hideCondition">
      <div class="container">
        <ol class="arrows">
          <li>
            <router-link :to="homeLink">{{ $t("product-page.main") }}</router-link>
          </li>
          <li>
            <router-link :to="data.searchQuery" onclick="return false;">
              {{ (categoryName === 'vacation_pack') ? $t("product-page.packages-to") :  (categoryName === 'Organize_tour_packages') ? $t("search-result.organize-packages") : $t("search-result.flight-only")}} {{ `${$t('search-result.to')}${data.dest}` }}
            </router-link>
          </li>
        </ol>
      </div>
    </div>
    <div :class="['breadcrumb', 'flat', stage >= 3 ? 'active' : '']" v-if="type === 'flat'">
      <a href="#" :class="{ active: stage >= 1 }" onclick="return false;">{{ $t("product-page.vacation-package-details") }}</a>
      <a href="#" :class="{ active: stage >= 2 }" onclick="return false;">{{ $t("product-page.payment-details") }} - {{ $t("product-page.safe-purchase") }}</a>
      <a href="#" :class="{ active: stage >= 3 }" onclick="return false;">{{ $t("product-page.order-completion") }}</a>
    </div>
    <div class="breadcrumb_area" v-if="type === 'list-area'">
      <div class="container">
        <ol class="arrows">
          <li v-for="(d, inx) in listLinkData" :key="inx">
            <a :href="d.link">{{ d.text }}</a>
          </li>
        </ol>
      </div>
    </div>
    <div :class="['breadcrumb', 'flat', listLabelData.stage >= 2 ? 'active' : '']" v-if="type === 'list-flat'">
      <a href="#" :class="{ active: listLabelData.stage >= inx }" onclick="return false;" v-for="(d, inx) in listLabelData.textList" :key="inx">
        {{d.text}}</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    type: {
      type: String,
      default: 'area',
    },
    categoryName: {
      type: String,
      default: 'vacation_pack',
    },
    data: {
      type: Object,
      default: null,
    },
    stage: {
      type: Number,
      default: 1,
    },
    // the props for destination and hotel list.
    listLinkData: {
      type: Array,
      default: () => [],
    },
    listLabelData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      originHomeLink: 'GET_HOME_LINK',
      marketerId: 'GET_MARKETER_ID',
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
    }),
    hideCondition() {
      const { isAgencyLogin, bypassPaymentState, limitDealsState, isMaccabiAgency } = this;
      return !isMaccabiAgency && ((!isAgencyLogin && bypassPaymentState) || limitDealsState);
    },
    searchQuery() {
      const obj = sessionStorage.getItem('search-query') ? sessionStorage.getItem('search-query') : '#';
      // this.$log.debug(new URLSearchParams(JSON.parse(obj)).toString());
      return `/search-result?${sessionStorage.getItem('search-query') ? new URLSearchParams(JSON.parse(obj)).toString() : '#'}`;
    },
    landingQuery() {
      return window.sessionStorage.getItem('landing-url') ? `/${window.sessionStorage.getItem('landing-url')}` : '';
    },
    homeLink() {
      return this.originHomeLink;
    },
  },
  mounted() {
  },
  methods: {
    gotoSearch() {
      this.$router.push({ path: '/search-result', query: JSON.parse(this.searchQuery) });
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  display: inline-block;
  overflow: hidden;
  border-radius: 0;
  counter-reset: flag;
  width: 100%;
  text-align: right;
  padding: 0;
  margin-top: 15px;
  background-color: #949494;
}

  .bonauf-mobile .breadcrumb-wrapper {
    display: none;
  }

@media (max-width: 479px) {
  .breadcrumb-wrapper {
    display: none;
  }
}
</style>
